import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentFormatter from '../components/content-formatter'

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  render() {
    let post = this.props.data.wpPage
    let seo = post?.seo
    let title = post?.title

    return (
      <Layout className="home-page" hideFooter={false}>
        <SEO title={title} image={seo?.opengraphImage?.localFile?.publicURL}
        description={seo?.metaDesc} />
        <div className="has-background-white home-page">
          <div className="content columns">
            <ContentFormatter content={post?.content ? post?.content : ''} />
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    wpPage(isFrontPage: {eq: true}) {
      slug
      template {
        ... on WpDefaultTemplate {
          templateName
        }
      }
      title
      databaseId
      parentId
      contentType {
        node {
          name
        }
      }
      status
      uri
      content
      seo {
        metaDesc
        opengraphImage {
          localFile {
            publicURL
          }
        }
        metaKeywords
        opengraphDescription
      }
    }
  }
`
